/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components'

const TitleSecondary = styled.h2`
  font-size: ${(props) => props.theme.font.size.l};
  font-family: ${(props) => props.theme.font.family.secondary};
`

export default TitleSecondary
